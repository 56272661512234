export const USER_KEY = 'api_user';

export const isBrowser = typeof window !== "undefined";

export const saveData = (key, data) => {
  if(isBrowser) {
    window.localStorage.setItem(key, data);
  }
};

export const clearData = (key) => {
  if(isBrowser){
    window.localStorage.removeItem(key);
  }
}

export const getData = (key) => {
  if(isBrowser) {
    const value = window.localStorage.getItem(key);

    if (value?.startsWith('{')) {
      return JSON.parse(value);
    }
    return value;
  }
};
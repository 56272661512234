import { getData } from './store';

const languages = {
  'cy': 'welsh'
};

export const getLanguage = () => {
  const language = getData('language');
  return languages[language];
};

export const getResourceText = (key) => resourceText[getLanguage()]?.[key] ?? resourceText[key];

const resourceText = {
  username: 'Username',
  password: 'Password',
  login: 'Login',
  loading: 'Loading...',
  usernameErrorMessage: 'Please enter your username',
  passwordErrorMessage: 'Please enter your password',
  loginErrorMessage: 'Login failed. Username or password is not correct',
  loginErrorTimeoutMessage: 'Login failed. Please try again',
  loginMainHeading: 'Welcome to the Academy of Barmy Composers',
  loginSubHeading: 'Please Login',
  updatePasswordHeading: 'Please change your password',
  updatePasswordText: 'After logging in for the first we require you to update your password',
  currentPassword: 'Current password',
  newPassword: 'New password',
  newPasswordAgain: 'Confirm new password',
  newPasswordErrorMessage: 'Please use at least 5 characters',
  passwordsDontMatch: 'Both new passwords must match each other',
  changePasswordErrorMessage: 'Error updating password. Please try again',
  updatePassword: 'Update password',
  professorPeriWelcomeAltText: 'Professor Peri welcomes you to ABC of Opera',
  userNotActiveErrorMessage: 'Sorry your account is inactive. Please contact us',

  dashboardHeading: 'Welcome ',
  dashboardResourcesLoading: 'Loading Teacher Resources...',
  dashboardNoResourcesSubHeading: 'No teaching resources found',
  dashboardNoResourcesReasonText: 'This could be due to the following:',
  dashboardNoResourcesReasonOne: 'You have no subscriptions. Please contact us to purchase a subscription.',
  dashboardNoResourcesReasonTwo: 'Your existing subscription has expired. Please contact us to renew your subscription.',
  dashboardResourcesHeading: 'Here are your teaching resources',
  dashboardError: 'Error loading teacher resources',
  dashboardErrorText: 'There has been a problem loading the teacher resources. Please try again by refreshing the page.',

  resourceSectionsLoading: 'Loading sections...',
  backToDashboardLinkText: 'back to all teacher resources',
  sectionError: 'Error loading teacher resources',
  sectionErrorText: 'There has been a problem loading the teacher resources. Please try again by refreshing the page.',
  sectionNameError: 'Lesson group name cannot be empty',

  lessonsLoading: 'Loading lessons...',

  backTo: 'back to',
  admin: 'Admin',
  logout: 'Logout',

  resourcePacks: 'Resource Packs',
  organisations: 'Organisations',
  users: 'Users',
  subscriptions: 'Subscriptions',

  addNewResourcePack: 'Add new resource pack',
  noResourcePacksFound: 'No Resource packs found',
  createResourcePack: 'Create Resource Pack',
  updateResourcePack: 'Update Resource Pack',
  resourcePackNameError: 'Resource pack name cannot be empty',
  createResourcePackApiError: 'Error creating teacher resource. Please try again.',
  updateResourcePackApiError: 'Error updating teacher resource. Please try again.',
  cancel: 'Cancel',
  name: 'Name',
  edit: 'Edit',
  add: 'Add',

  teacherNotesURL: 'Teacher notes URL',
  sectionNotesURL: 'Section notes URL',
  teacherNotesURLError: 'This should be a google docs URL',
  downloadTeacherNotesFor: 'Download teacher notes and suggestions for',
  lessonsInPackHeading: 'Lessons in this pack',
  downloadablesInPackHeading: 'Downloadable resources in this pack',
  addNewLessonGroup: 'Add New Lesson Group',
  updateLessonGroup: 'Update Lesson Group',
  createLessonGroup: 'Create Lesson Group',
  createSectionApiError: 'Error creating lesson group. Please try again.',
  updateSectionApiError: 'Error updating lesson group. Please try again.',

  noGroupsInResourcePack: 'No lesson groups',
  getSectionsError: 'Error getting lesson groups',
  noWeeksInGroup: 'No weeks in lesson group',
  addNewWeek: 'Add New Week',
  createWeek: 'Create week',
  weekNameError: 'Week name cannot be empty',
  createWeekApiError: 'Error creating week. Please try again.',
  getWeeksError: 'Error getting weeks',
  updateWeek: 'Update week',
  updateWeekApiError: 'Error updating week. Please try again.',

  getLessonsError: 'Error getting lessons',
  getDownloadableError: 'Error getting downloadable resource',
  noLessonsInWeek: 'No lessons in this week',
  noDownloadablesInWeek: 'No downloadable resources in this week',
  addLesson: 'Add Lesson',
  addDownloadable: 'Add Downloadable Resource',
  createLesson: 'Create Lesson',
  backToResourcePack: 'Back to Resource Pack',
  createDownloadable: 'Create Downloadable Resource',
  lessonNameError: 'Lesson name cannot be empty',
  downloadableNameError: 'Downloadable resource name cannot be empty',
  downloadableURLError: 'Downloadable resource url cannot be empty',
  downloadableURLFormatError: 'Downloadable resource URL must be a Google Docs URL',
  createLessonApiError: 'Error creating lesson. Please try again.',
  createDownloadableApiError: 'Error creating downloadable resource. Please try again.',
  googleSlidesURL: 'Google Slides URL',
  youtubeURL: 'YouTube URL',
  downloadableURL: 'Google Docs URL',
  googleSlidesOrYouTubeError: 'Google Slides or YouTube URL must be set, but not both',
  googleSlidesFormatError: 'This must be a google slides URL',
  lessons: 'Lessons',
  downloadables: 'Downloadable Resources',
  noLessonsInResourcePack: 'No Lessons in this resource pack',
  noDownloadablesInResourcePack: 'No Downloadable resources in this resource pack',
  noLessonResource: 'No Lesson Resource',
  noDownloadableResource: 'No Downloadable Resource',
  updateLesson: 'Update Lesson',
  updateDownloadable: 'Update Downloadable Resource',
  updateLessonApiError: 'Error updating lesson. Please try again',
  updateDownloadablesApiError: 'Error updating downloadable resource. Please try again',
  lessonPreview: 'Preview',
  downloadablePreview: 'Preview',
  delete: 'Delete',
  confirmDeleteResourcePackHeading: 'Delete Resource Pack',
  confirmDeleteResourcePackText: 'Deleting the resource pack will also delete all the lessons and downloadable resources associated with this pack. Are you sure?',

  noUsersFound: 'No Users Found',
  usernameTableHeading: 'Username',
  emailTableHeading: 'Email',
  organisationTableHeading: 'Organisation',
  userLastLoginTableHeading: 'Last Login',
  adminUserTableHeading: 'Admin User',
  isActiveTableHeading: 'Active',
  actionsTableHeading: 'Actions',
  createUser: 'Create user',
  updateUsersApiError: 'Error updating user. Please try again',
  createUserApiError: 'Error creating user. Please try again',
  updateUser: 'Update user',
  email: 'Email',
  userId: 'User Id',
  isActive: 'Active',
  isSystemAdmin: 'System Admin',

  userIdError: 'User Id cannot be empty and must only contain A-Z a-z 0-9 characters',
  userIdDuplicateError: 'User Id already in use',
  emailError: 'Email must be in the format something@somewhere.there',

  noSubscriptionsFound: 'No Subscriptions found',
  resourcePackTableHeading: 'Resource pack',
  startDateTableHeading: 'Start Date',
  expiresDateTableHeading: 'Expires Date',
  createSubscription: 'Create Subscription',
  updateSubscription: 'Update Subscription',
  createSubscriptionApiError: 'Error creating subscription. Please try again',
  updateSubscriptionApiError: 'Error updating subscription. Please try again',
  organisationError: 'Please select and an organisation',
  resourcePackError: 'Please select and a resource pack',
  startDate: 'Start Date',
  startDateError: 'Start date is invalid',
  expiryDate: 'Expiry Date',
  expiryDateError: 'Expiry date is invalid',
  threeMonths: '3 Months',
  sixMonths: '6 Months',
  twelveMonths: '12 Months',
  resourcePack: 'Resource Pack',

  noOrganisationsFound: 'No Organisations found',
  organisation: 'Organisation',
  createOrganisation: 'Create Organisation',
  organisationNameError: 'Organisation name cannot be empty',
  createOrganisationApiError: 'Error creating Organisation. Please try again',
  updateOrganisation: 'Update Organisation',
  editOrganisationApiError: 'Error updating organisation. Please try again',
  resetPassword: 'Reset password',
  lastLogin: 'Last logged in',
  lastResourceAccessed: 'Last resource accessed',
  notLoggedInYet: 'User not logged in yet',
  created: 'Created',
  updated: 'Updated',
  confirmDeleteSubscriptionHeading: 'Confirm Delete Subscription',
  confirmDeleteSubscriptionText: 'Delete subscription. Are you sure?',

  continueWithResource: 'Continue with',

  resourceHTMLType: 'Type',
  resourceHTMLTypeError: 'Please choose a resource type',
  academyOfBarmyComposers: 'Academy of Barmy Composers',

  // this needs reviewing
  welsh: {
    // username: 'Enw defnyddiwr',
    // password: 'Cyfrinair',
    // login: 'Mewngofnodi',
    // loading: 'Llwytho...',
    // usernameErrorMessage: 'Rhowch eich enw defnyddiwr',
    // passwordErrorMessage: 'Rhowch eich cyfrinair',
    // loginErrorMessage: 'Methodd mewngofnodi. Nid yw\'r enw defnyddiwr na\'r cyfrinair yn gywir',
    // loginMainHeading: 'Croeso i ABC of Opera',
    // loginSubHeading: 'Os gwelwch yn dda Mewngofnodi',
    // professorPeriWelcomeAltText: 'Professor Peri yn eich croesawu i ABC of Opera',
    // loginErrorTimeoutMessage: 'Methodd mewngofnodi. Trio eto os gwelwch yn dda',
    // userNotActiveErrorMessage: 'Mae\'n ddrwg gennym fod eich cyfrif yn anactif. Cysylltwch â ni',
    //
    // dashboardHeading: 'Croeso ',
    // dashboardResourcesLoading: 'Wrthi\'n llwytho Adnoddau Athrawon...',
    // dashboardNoResourcesSubHeading: 'Ni ddaethpwyd o hyd i unrhyw adnoddau addysgu',
    // dashboardNoResourcesReasonText: 'Gallai hyn fod oherwydd y canlynol:',
    // dashboardNoResourcesReasonOne: 'Nid oes gennych unrhyw danysgrifiadau. Cysylltwch â ni i brynu tanysgrifiad.',
    // dashboardNoResourcesReasonTwo: 'Mae eich tanysgrifiad presennol wedi dod i ben. Cysylltwch â ni i adnewyddu eich tanysgrifiad.',
    // dashboardResourcesHeading: 'Dyma eich adnoddau addysgu',
    // dashboardError: 'Gwall wrth lwytho adnoddau athrawon',
    // dashboardErrorText: 'Bu problem wrth lwytho adnoddau athrawon. Ceisiwch eto drwy adnewyddu\'r dudalen.',
    //
    // resourceSectionsLoading: 'Wrthi\'n llwytho adrannau...',
    // backToDashboardLinkText: 'yn ôl at yr holl adnoddau athrawon',
    // sectionError: 'Gwall wrth lwytho adnoddau athrawon',
    // sectionErrorText: 'Bu problem wrth lwytho adnoddau athrawon. Ceisiwch eto drwy adnewyddu\'r dudalen.',
    // sectionNameError: 'Ni all enw grŵp gwers fod yn wag',
    //
    // lessonsLoading: 'Llwytho gwersi...',
    //
    // backTo: 'Nôl i',
    //
    // addNewResourcePack: 'Ychwanegu pecyn adnoddau newydd',
    // noResourcePacksFound: 'Ni ddaethpwyd o hyd i becynnau adnoddau',
    // createResourcePack: 'Creu Pecyn Adnoddau',
    // updateResourcePack: 'Diweddaru\'r Pecyn Adnoddau',
    // resourcePackNameError: 'Ni all enw pecyn adnoddau fod yn wag',
    // createResourcePackApiError: 'Gwall wrth greu adnodd athro. Trio eto os gwelwch yn dda.',
    // updateResourcePackApiError: 'Gwall wrth ddiweddaru\'r adnodd athro. Trio eto os gwelwch yn dda.',
    // cancel: 'Canslo',
    // name: 'Enw',
    // edit: 'Golygu',
    // add: 'Ychwanegu',
    //
    // lessonsInPackHeading: 'Gwersi yn y pecyn hwn',
    // addNewLessonGroup: 'Ychwanegu Grŵp Gwersi Newydd',
    // updateLessonGroup: 'Diweddaru\'r Grŵp Gwers',
    // createLessonGroup: 'Creu Grŵp Gwersi',
    // createSectionApiError: 'Gwall wrth greu grŵp gwers. Trio eto os gwelwch yn dda.',
    // updateSectionApiError: 'Gwall wrth ddiweddaru\'r grŵp gwers. Trio eto os gwelwch yn dda.',
    //
    // noGroupsInResourcePack: 'Dim grwpiau gwersi',
    // getSectionsError: 'Gwall wrth gael grwpiau gwersi',
    // noWeeksInGroup: 'Dim wythnosau yn y grŵp gwers',
    // addNewWeek: 'Ychwanegu Wythnos Newydd',
    // createWeek: 'Creu wythnos',
    // weekNameError: 'Ni all enw\'r wythnos fod yn wag',
    // createWeekApiError: 'Gwall wrth greu wythnos. Trio eto os gwelwch yn dda.',
    // getWeekError: 'Gwall wrth gael wythnos',
    // updateWeek: 'Wythnos diweddaru',
    // updateWeekApiError: 'Gwall wrth ddiweddaru\'r wythnos. Trio eto os gwelwch yn dda.',
    //
    // noLessonsWeek: 'Dim gwersi yr wythnos hon',
    // addLesson: 'Ychwanegu Gwers',
    // createLesson: 'Creu Gwers',
    // lessonNameError: 'Ni all enw gwers fod yn wag',
    // createLessonApiError: 'Gwall wrth greu gwers. Trio eto os gwelwch yn dda.',
    // googleSlidesOrYouTubeError: 'Rhaid gosod Google Slides neu YouTube URL, ond nid y ddau',
    // lessons: 'Gwersi',
    // noLessonsInResourcePack: 'Dim Gwersi yn y pecyn adnoddau hwn',
    // noLessonResource: 'Dim Adnodd Gwers',
    // updateLesson: 'Diweddaru\'r Wers',
    // updateLessonApiError: 'Gwall wrth ddiweddaru\'r wers. Trio eto os gwelwch yn dda',
    // lessonPreview: 'Rhagolwg',
    // delete: 'Dileu',
    // confirmDeleteResourcePackHeading: 'Dileu Pecyn Adnoddau',
    // confirmDeleteResourcePackText: 'Bydd dileu\'r pecyn adnoddau hefyd yn dileu\'r holl wersi sy\'n gysylltiedig â\'r pecyn hwn. Wyt ti\'n siwr?',
    //
    // noUsersFound: 'Dim Defnyddwyr Wedi\'u Canfod',
    // usernameTableHeading: 'Enw defnyddiwr',
    // emailTableHeading: 'Ebost',
    // organisationTableHeading: 'Sefydliad',
    // userLastLoginTableHeading: 'Mewngofnodi Diwethaf',
    // adminUserTableHeading: 'Defnyddiwr Gweinyddol',
    // activeUserTableHeading: 'Actif',
    // actionsTableHeading: 'Gweithredoedd',
    // createUser: 'Creu defnyddiwr',
    // updateUsersApiError: 'Gwall wrth ddiweddaru\'r defnyddiwr. Trio eto os gwelwch yn dda',
    // createUserApiError: 'Gwall wrth greu defnyddiwr. Trio eto os gwelwch yn dda',
    // updateUser: 'Diweddaru defnyddiwr',
    // email: 'Ebost',
    // userId: 'ID Defnyddiwr',
    // isActive: 'Actif',
    // isSystemAdmin: 'Gweinyddwr System',
    //
    // userIdError: 'Ni all ID Defnyddiwr fod yn wag',
    // userIdDuplicateError: 'ID Defnyddiwr eisoes yn cael ei ddefnyddio',
    // emailError: 'Rhaid i\'r e-bost fod yn y fformat rhywbeth@rhywle.there',
    //
    // noSubscriptionsFound: 'Ni chanfuwyd Tanysgrifiadau',
    // resourcePackTableHeading: 'Pecyn adnoddau',
    // startDateTableHeading: 'Dyddiad cychwyn',
    // expiresDateTableHeading: 'Dyddiad Dod i Ben',
  }
};
